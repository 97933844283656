@keyframes rainbow-animation {
  0%, 100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

@keyframes fade-in-scale {
  0% {
    opacity: 0;
    transform: scale(.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.rainbow {
  color: #0000;
  background: linear-gradient(to right, #66f, #09f, #0f0, #f39, #66f) 0 0 / 400% 100% text;
  animation: 6s ease-in-out infinite rainbow-animation;
}

.fade-in-scale {
  animation: .1s ease-in-out fade-in-scale;
}
/*# sourceMappingURL=repo.35de216e.css.map */
